<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="olaen"
      sort-by="leistung"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <help />
          <v-toolbar-title>Ola</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import OlaService from "@/services/old/OlaService.js";
import Help from "@/components/old/help/OlaHelp.vue";
export default {
  components: { Help },
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Leistung",
        align: "start",
        sortable: false,
        value: "leistung"
      },
      { text: "Leistungserbringer", value: "leistungserbringer" },
      { text: "Jahr", value: "jahr" },
      { text: "Summe", value: "summe" },
      { text: "Januar", value: "m01" },
      { text: "Februar", value: "m02" }
    ],
    rules: {
    },
    ola: [],
    olaen: [],
    editedIndex: -1,
    editedItem: {
      leistung: "",
      leistungserbringer: "",
      summe: "",
      jahr: ""
    },
    defaultItem: {
     leistung: "",
      leistungserbringer: "",
      summe: "",
      jahr: "",
      m01:"",
      m02:"",
    },
    isEditModus: false,
    snackbar: false,
    message: "",
    snackbar_timeout: 2000,
    form_saved: true
  }),
  computed: {
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadOlas();
    },
    async loadOlas() {
      let resp = await OlaService.getOla(
        this.$store.getters.currentSystemId
      );
      this.olaen = resp.data;
      if (resp.status == 200) {
        this.message = "Olas gelesen.";
      } else {
        this.message = resp.data;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.form_saved) {
      const answer = window.confirm(
        "Änderungen sind noch nicht gespeichert worden.\nSollen die Änderungen verworfen werden?"
      );
      if (answer) {
        this.form_saved = true;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>

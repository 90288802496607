import axios from "axios";

export default {
  getOla(systemid) {
    return axios.get(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/ola/" +
        systemid.substring(1)
    );
  },
  getOlas() {
    return axios.get(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/ola"
    );
  }
};
